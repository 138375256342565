import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up a listener for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError('');

    try {
      setLoading(true);
      await auth.signInWithEmailAndPassword(email, password);

      console.log('User logged in successfully');
      navigate('/admin');

      setEmail('');
      setPassword('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Redirect to login or home page after logout
      navigate('/');
    } catch (err) {
      console.error('Logout error:', err.message);
    }
  };

  return (
    <div className="login_form_container">
      <div className="login-form">
        {user ? (
          <div>
            <p className="login_text">You are already logged in.</p>

            <div className="login_buttons_container">
              <button className="important" onClick={handleLogout}>Logout</button>
              <button className="important"><Link to="/admin">Admin Page</Link></button>
            </div>
          </div>
        ) : (<div>
          <h2>Login</h2>
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label><br />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label><br />
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="important login_btn_submit" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>
        )}
      </div>
    </div>
  )
}

export default Login;
