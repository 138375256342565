import React, { useState, useEffect } from 'react';
import '../../App.css';
import { IoClose } from "react-icons/io5";

import { db } from '../../firebase-config';
import { app } from '../../firebase-config';
import { collection, addDoc, updateDoc, deleteDoc, getFirestore, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function Modal({ isOpen, onClose, formLocation, documentData, documentType }) {
  const [formData, setFormData] = useState({ product: '', price: '', description: '', imgsrc: '', cost: '', time: '', id: '' });
  const firestore = getFirestore();
  const [image, setImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [displayDownloadUrl, setDisplayDownloadUrl] = useState('');

  useEffect(() => {
    if (documentData && formLocation !== "Create") {
      setFormData({
        product: documentData.product || '',
        price: documentData.price || '',
        description: documentData.description || '',
        imgsrc: documentData.imgsrc || '',
        cost: documentData.cost || '',
        time: documentData.time || '',
        id: documentData.id || ''
      });
    } else if (formLocation === "Create") {
      setFormData({ product: '', price: '', cost: '', description: '', imgsrc: '', time: '', id: '' });
    }
  }, [documentData]);

  if (!isOpen) return null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    formLocation === "Create" ? handleCreate(event) : handleUpdate(event, documentData);
  };

  const handleCreate = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, 'products'), {
        name: formData.product,
        description: formData.description,
        img: formData.imgsrc,
        price: formData.price,
        cost: formData.cost,
        time: formData.time,
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
    window.location.reload();
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    console.log(formData);

    const docRef = doc(firestore, "products", documentData.id);

    try {
      await updateDoc(docRef, {
        product: formData.product,
        description: formData.description,
        src: formData.imgsrc,
        price: formData.price,
        cost: formData.cost,
        time: formData.time
      });
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    window.location.reload();
  };

  const handleDelete = async (documentId) => {
    const firestore = getFirestore();
    const docRef = doc(firestore, "products", documentId);

    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    window.location.reload();
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!image) {
      setUploadStatus('No file selected.');
      return;
    }
  
    const storage = getStorage(app);
    const storageRef = ref(storage, `${image.name}`);
  
    try {
      await uploadBytes(storageRef, image);
  
      const downloadURL = await getDownloadURL(storageRef);
      setDisplayDownloadUrl(downloadURL);
  
      setUploadStatus('Upload successful!');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('Upload failed.');
    }
  };  

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <button className="close-btn important" onClick={onClose}><IoClose /></button>
          <h2>{formLocation} {documentType}</h2>{formLocation !== "Delete" && formLocation !== "UploadImg" ? (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="imgsrc">Image Src:</label><br />
                <input type="text"
                  id="imgsrc"
                  name="imgsrc"
                  value={formData.imgsrc}
                  onChange={handleChange}
                  required />
              </div>
              <div>
                <label htmlFor="product">Product:</label><br />
                <input type="text"
                  id="product"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  required />
              </div>
              <div>
                <label htmlFor="price">Price:</label><br />
                <input type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required />
              </div>
              <div>
                <label htmlFor="description">Description:</label><br />
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  rows="5"
                  cols="30"
                />
              </div>
              <div>
                <label htmlFor="time">Time:</label><br />
                <input type="text"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required />
              </div>
              <div className="section-action-button">
                <button type="submit" className="important">Submit</button>
              </div>
            </form>) : formLocation === "UploadImg" ? (
              <form onSubmit={handleUpload}>
                <div>
                  <label htmlFor="imageUpload">Upload Image:</label><br />
                  <input
                    type="file"
                    id="imageUpload"
                    name="imageUpload"
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                  />
                </div>
                <div className="section-action-button">
                  <button type="submit" className="important">Upload</button>
                </div>
                <p>{displayDownloadUrl}</p>
              </form>
            ) : (
            <div>
              <p>Are you sure you want to delete this product? <br /> {documentData.product}, ${documentData.price}</p>

              <button className="important" onClick={() => handleDelete(documentData.id)}>Delete</button>

            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;