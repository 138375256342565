// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDJtmowTAo_nxWXP69Povzhu0E79E7Hr40",
    authDomain: "crochet-website-160c5.firebaseapp.com",
    projectId: "crochet-website-160c5",
    storageBucket: "crochet-website-160c5.appspot.com",
    messagingSenderId: "706326571376",
    appId: "1:706326571376:web:5c498f876df302a937c7b5",
    measurementId: "G-35MBQR3PZJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };